import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { fetchDocuments } from "../../../../../api/CRM/Documents";
import Loader from "../../../../../components/Loader";
import DeleteUsersDocuments from "../DeleteUsersDocuments";
import { useState } from "react";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../../components/CustomButton";

const styles = createUseStyles({
  ...modalGenericStyles,
  UserDocuments: {
    ...modalGenericStyles.cardBody,

    minHeight: "10vh",
  },
  cardBody: {
    gridGap: variables.normal_gap,
  },
  table: tableStyles.table,
  thead: tableStyles.head,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
});

const UserDocuments = ({ tags, queryKey, sourceEntity, type }) => {
  const classes = styles();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUid, setDeleteUid] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: [queryKey, tags, type],
    queryFn: () =>
      fetchDocuments({
        params: { source_entity: sourceEntity, tags: tags.join(",") },
      }),
    onError: (error) => toast.error(error),
    enabled: !showDeleteModal,
  });
  return (
    <div className={classes.UserDocuments}>
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>Source Entity</th>
              <th className={classes.headCell}>File Name</th>
              <th className={classes.headCell}>Type</th>
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data", []).map((doc, idx) => (
              <tr key={idx}>
                <td className={classes.tableCell}>
                  {_.get(doc, "source_entity") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {_.get(doc, "filename") || "N/A"}
                </td>
                <td className={classes.tableCell}>
                  {doc.tags_display[1]
                    ? doc.tags_display[1].split("_")[1]
                    : "N/A"}
                </td>
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={(e) => {
                      e.preventDefault();
                      setDeleteUid(doc.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      window.open(doc.path, "_blank", "noreferrer");
                    }}>
                    <strong>Download</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDeleteModal && (
        <DeleteUsersDocuments
          uid={deleteUid}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};
UserDocuments.propTypes = {
  sourceEntity: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
};
export default UserDocuments;
