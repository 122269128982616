import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Loader from "../../../components/Loader";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../variables";
import _ from "lodash";
import Navbar from "./Navbar";
import { fetchWebsites } from "../../../api/DistributionSite/webSites";
import ControlStrip from "../../../components/ControlStrip";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../helpers";
import DetailWebSiteModal from "../DistributionSitesModals/DetailWebSiteModal";
import EditWebSiteModal from "../DistributionSitesModals/EditWebSiteModal";
import DeleteWebSiteModal from "../DistributionSitesModals/DeleteWebSiteModal";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../hooks";
import * as yup from "yup";
import { genericRequestErrorHandler } from "../../../api";
import { CustomButton } from "../../../components/CustomButton";

const initialFilters = {
  page_size: 20,
  page: 1,
  ordering: "",
  discending: "",
  name__icontains: "",
  payment_type: "",
  payment_supplier: "",
  product_categories: "",
  currency: "",
  created__gte: "",
  created__lte: "",
  name: "",
  status: "",
};

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const webSitesStyles = createUseStyles({
  WebSites: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
  checkBox: tableStyles.checkBox,
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const WebSites = () => {
  const classes = webSitesStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [webSiteId, setWebSiteId] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["DistributionSites", filters],
    queryFn: async () => await fetchWebsites({ params: filters }),
    onError: (error) => genericRequestErrorHandler(error),
    enabled: !showDetailModal && !showEditModal,
  });

  const tableHeaders = {
    Name: (val) => val.name || "N/A",
    "Source Entity": (val) => val.source_entity,
    "Payment Supplier": (val) => val.payment_supplier_display,
    Accommodation: (val) =>
      _.get(trueFalseMapping, _.get(val, "accommodation")),
    Activities: (val) => _.get(trueFalseMapping, _.get(val, "activities")),
    Addons: (val) => _.get(trueFalseMapping, _.get(val, "addons")),
    Flights: (val) => _.get(trueFalseMapping, _.get(val, "flights")),
    "Market Place": (val) => _.get(trueFalseMapping, _.get(val, "marketplace")),
    Packages: (val) => _.get(trueFalseMapping, _.get(val, "packages")),
    Restaurants: (val) => _.get(trueFalseMapping, _.get(val, "restaurants")),
    Transfers: (val) => _.get(trueFalseMapping, _.get(val, "transfers")),
    "Cloudfront Id": (val) => val.cloudfront_id || "N/A",
    "S3 Bucket": (val) => val.s3_bucket || "N/A",
    Created: (val) => val.created || "N/A",
    Status: (val) => val.status_display || "N/A",
  };
  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  return (
    <div className={classes.WebSites}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setShowEditModal(true);
          setWebSiteId(null);
        }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={filters.page_size}
        page={filters.page}
        count={_.get(data, "count", 0)}
        onChangeLimit={(page_size) =>
          setFilters({ ...filters, page_size, page: 1 })
        }
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {isLoading && <Loader onTop={true} />}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr className={classes.headRow}>
              <th className={classes.headCell}>#</th>
              {Object.keys(tableHeaders).map((value, idx) => (
                <th key={idx} className={classes.headCell}>
                  {value}
                </th>
              ))}
              <th className={classes.headCell}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.get(data, "data.results", []).map((val, idx) => (
              <tr className={classes.bodyRow} key={idx}>
                <td className={classes.tableCell}>{idx + 1}</td>
                {Object.entries(tableHeaders).map((entry, ridx) => {
                  var cell = null;
                  const fn = entry[1];
                  switch (entry[0].toLowerCase()) {
                    case "created":
                      cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                      return (
                        <td className={classes.datesCell} key={ridx}>
                          {cell}
                        </td>
                      );
                    default:
                      cell = fn(val || "N/A");
                  }
                  return (
                    <td className={classes.tableCell} key={ridx}>
                      {cell}
                    </td>
                  );
                })}
                <td className={classes.actionCell}>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setWebSiteId(val.uid);
                      setShowDetailModal(true);
                    }}>
                    <strong>Details</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setWebSiteId(val.uid);
                      setShowEditModal(true);
                    }}>
                    <strong>Edit</strong>
                  </CustomButton>
                  <CustomButton
                    appearance="ghost"
                    onClick={() => {
                      setDeleteData(val);
                      setWebSiteId(val.uid);
                      setShowDeleteModal(true);
                    }}>
                    <strong>Delete</strong>
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDetailModal && (
        <DetailWebSiteModal
          webSiteId={webSiteId}
          onClose={() => {
            setShowDetailModal(false);
            setWebSiteId(null);
          }}
        />
      )}
      {showEditModal && (
        <EditWebSiteModal
          webSiteId={webSiteId}
          onClose={() => {
            setShowEditModal(false);
            setWebSiteId(null);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteWebSiteModal
          data={deleteData}
          uid={webSiteId}
          onClose={() => {
            setShowDeleteModal(false);
            // setChecked([]);
          }}
        />
      )}
    </div>
  );
};
export default WebSites;
