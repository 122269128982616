import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchCoachReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/coach/`,
    {
      params,
    }
  );
}

export function retrieveCoachReservation({ reference }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/coach/${reference}/`
  );
}
export async function patchCoachReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/coach/${reference}/`,
    payload
  );
}
export function getCoachReservationStats({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/coach/stats`,
    { params }
  );
}
