import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import { handleApplyFilters, medWeekdayDatetimer } from "../../../../helpers";
import {
  genericIndexesStyles,
  btnStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import { fetchEmailConfigReservations } from "../../../../api/CRS/EmailConfiguration";
import DetailEmailConfigurationModal from "../EmailConfigurationModals/DetailEmailConfigurationModal";
import EditEmailConfigurationModal from "../EmailConfigurationModals/EditEmailConfigurationModal";
import { useQuery } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { useListPageSidebar } from "../../../../hooks";
import * as yup from "yup";
import { genericRequestErrorHandler } from "../../../../api";
import { CustomButton } from "../../../../components/CustomButton";

const navbarSchema = yup.object().shape({
  title__icontains: yup
    .string()
    .min(2, "Title must be more than 1 character")
    .max(100, "Title must be less than 100 characters"),
});

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  title__icontains: "",
  email_type: "",
  reservation_type: "",
  market_type: "",
  created__gte: "",
  created__lte: "",
};
const statusMapping = {
  "": "-----",
  SU: "Success",
  FA: "Failed",
  PE: "Pending",
};
const emailTypeMapping = {
  VO: "Voucher",
  CXL: "Cancellation",
  RQ: "On Request",
  FA: "Failed Reservation",
  OFF: "Offer Notification",
};
const reservationTypeMapping = {
  ACC: "Accommodations",
  GEN: "General Services",
  MI: "Addons",
  TRF: "Transfers ",
  PKG: "Packages ",
  FL: "Flights",
  COO: "Coordinators",
  TRA: "Trains",
  FER: "Ferrys",
  COA: "Coaches",
  REST: "Restaurants",
  ACT: "Activities",
  CAR: "Car Rentals",
};

const EmailConfigurationStyles = createUseStyles({
  EmailConfiguration: genericIndexesStyles.indexes.mobile(),
  tableContainer: genericIndexesStyles.tableContainer.mobile(),
  table: tableStyles.table,
  thead: tableStyles.head,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile(),
  btn: btnStyles.btn,
  checkBox: {
    ...tableStyles.checkBox,
  },
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  [`@media ${variables.media.smallscreen}`]: {
    actionCell: {
      // gridTemplateColumns: "repeat(2,1fr) ",
      paddingTop: `${variables.normal_gap}!important`,
    },
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const EmailConfiguration = () => {
  const classes = EmailConfigurationStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [selectedId, setSelectedId] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  //   const [checked, setChecked] = useState([]);

  const tableHeaders = {
    Title: (val) => val.title,
    "Email Type": (val) => _.get(emailTypeMapping, _.get(val, "email_type")),
    "Is Global": (val) => (val.is_global ? "Yes" : "No"),
    // "Reservation Type": (val) =>
    //   _.get(reservationTypeMapping, _.get(val, "reservation_types").join(",")),
    "Reservation Type": (val) => {
      const reservationTypes = _.get(val, "reservation_types");
      const mappedTypes = reservationTypes.map((type) =>
        _.get(reservationTypeMapping, type)
      );
      return mappedTypes.join(", ") || "N/A";
    },
    Sender: (val) => val.sender,
    Subject: (val) => val.subject,
    "Source Entity": (val) => val.source_entity,
    "Template Name": (val) => val.template_name,
    "Market Type": (val) => val.market_type,
    Created: (val) => val.created,
    Status: (val) => _.get(statusMapping, _.get(val, "status")),
  };

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["emailsConfig", filters],
    queryFn: () =>
      fetchEmailConfigReservations({
        params: { ...filters },
      }),
    enabled: !showDetailModal && !showEditModal,
    onError: (error) => genericRequestErrorHandler(error),
  });

  return (
    <div className={classes.EmailConfiguration}>
      <Navbar
        filters={filters}
        initialFilters={initialFilters}
        onApplyFilters={handleApplyLocalFilters}
        onAdd={() => {
          setSelectedId(null);
          setShowAddModal(true);
        }}
        // onGo={(action) => {
        //   if (!checked.length) {
        //     return;
        //   }
        //   const fnMapping = {
        //     delete: setshowDeleteModal(true),
        //   };

        //   const actionFn = _.get(fnMapping, action);
        //   if (typeof actionFn === "function") {
        //     actionFn(true);
        //   }
        // }}
      />
      <HamburgerMenu />
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(data, "data.count", 0)}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th key={idx} className={classes.headCell}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(data, "data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tableCell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tableCell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setSelectedId(val.id);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                    <CustomButton
                      appearance="ghost"
                      onClick={() => {
                        setSelectedId(val.id);
                        setShowEditModal(true);
                      }}>
                      <strong>Edit</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailEmailConfigurationModal
          selectedId={selectedId}
          onClose={() => {
            setShowDetailModal(false);
            setSelectedId(null);
          }}
        />
      )}
      {showEditModal && (
        <EditEmailConfigurationModal
          selectedId={selectedId}
          onClose={() => {
            setShowEditModal(false);
            setSelectedId(null);
          }}
        />
      )}
      {showAddModal && (
        <EditEmailConfigurationModal
          onClose={() => {
            setShowAddModal(false);
          }}
        />
      )}
      {/* {showDeleteModal && (
       <Delete
          id={selectedId}
          onClose={() => {
            setSho(false);
            // setChecked([]);
          }}
        />
      )} */}
    </div>
  );
};
export default EmailConfiguration;
