import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { modalGenericStyles, variables } from "../../../../../variables";
import { toast } from "react-toastify";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import Loader from "../../../../../components/Loader/index";
import {
  deleteDocument,
  retrieveDocument,
} from "../../../../../api/CRM/Documents";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CustomButton } from "../../../../../components/CustomButton";

const basicDataCpl = [
  ["Tags", "tags_display"],
  ["Creator Username", "creator_metadata.username"],
  ["Creator First Name", "creator_metadata.first_name"],
  ["Creator Last Name", "creator_metadata.last_name"],
  ["Creator Email", "creator_metadata.email"],
  ["Source Entity", "creator_metadata.source_entity"],
  ["Filename", "filename"],
  ["Path", "path"],
];

const deleteUsersDocuments = createUseStyles({
  ...modalGenericStyles,
  DeleteUserDocument: {
    ...modalGenericStyles.modal,
  },
  card: { ...modalGenericStyles.card },
  basicInfo: {
    display: "grid",

    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
});

const DeleteUserDocument = ({ uid, onClose }) => {
  const classes = deleteUsersDocuments();

  const { data, isLoading } = useQuery({
    queryKey: ["DeleteUserDocument"],
    queryFn: () =>
      retrieveDocument({
        uid: uid,
      }),
    onError: (error) => toast.error(error),
  });

  const deleteUserDocumentMutation = useMutation(deleteDocument, {
    onSuccess: () => {
      toast.success("User Document Deleted");
      onClose();
    },
    onError: (error) => toast.error(error.message),
  });

  const onDelete = async (uid) => {
    await deleteUserDocumentMutation.mutateAsync({ uid });
  };

  return (
    <div className={classes.DeleteUserDocument}>
      {isLoading && <Loader onTop={true} />}
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>DELETE User Document</span>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.basicInfo}>
            <GenericBasicInfo
              header={"Are you sure you want to delete this?"}
              cardInfo={basicDataCpl}
              objectData={data}
            />
            <div className={classes.cardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(uid);
                }}>
                <strong>DELETE</strong>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
DeleteUserDocument.propTypes = {
  onClose: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
};
export default DeleteUserDocument;
