import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";

const QuilEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      theme="snow"
      onChange={(val, delta, source) => {
        if (source === "api") {
          return;
        }
        onChange(val);
      }}
      value={value}
    />
  );
};
QuilEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onchange: PropTypes.func,
};
export default QuilEditor;
