import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchCarRentalsReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/car-rental/`,
    {
      params,
    }
  );
}
export async function patchCarRentalReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/car-rental/${reference}/`,
    payload
  );
}
export async function retrieveCarRentalReservation({ reference }) {
  return await authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/car-rental/${reference}/`
  );
}

export function getCarRentalsReservationStats({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/car-rental/stats`,
    { params }
  );
}
