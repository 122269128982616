import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { toast } from "react-toastify";
import {
  cardStyles,
  formInputGroup,
  formSections,
  modalGenericStyles,
  variables,
} from "../../../../../../variables";
import PropTypes from "prop-types";
import { countries, handleValidationErrors } from "../../../../../../helpers";
import {
  patchHotel,
  retrieveHotel,
} from "../../../../../../api/accommodator/Hotels/Hotels";
import _ from "lodash";
import Loader from "../../../../../../components/Loader";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { genericRequestErrorHandler } from "../../../../../../api";
import QuilEditor from "../../../../../../components/QuilEditor";
import * as yup from "yup";
import { CustomButton } from "../../../../../../components/CustomButton";
import TabStrip from "../../../../../../components/TabStrip";

const jsonSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Must be at least 2 characters")
    .max(100, " Must be less than 100 characters")
    .default(""),
  address: yup.string().nullable(),
  supplier: yup
    .string()
    .nullable()
    .oneOf(
      ["innstant", "hotelbeds", "webhotelier", "miki", "hotelston"],
      "Invalid supplier"
    )
    .default(""),
  country_code: yup.string().default(""),
  rating: yup
    .number()
    .nullable()
    .min(1, "must be greater than or equal to 1")
    .max(5, "must be less than or equal to 5")
    .default(0),
  custom_doc_score: yup
    .number()
    .nullable()
    .min(0, "must be greater than or equal to 0")
    .default(0),
  description_en: yup.string().nullable().default(""),
  geodata: yup.object().shape({
    lat: yup
      .number()
      .nullable()
      .min(-90, "must be greater than or equal to -90")
      .max(90, "must be less than or equal to 90")
      .default(0),
    lng: yup
      .number()
      .nullable()
      .min(-180, "must be greater than or equal to -180")
      .max(180, "must be less than or equal to 180")
      .default(0),
  }),
});

const languages = {
  en: "English",
  // cn: "Chinese",
  // it: "Italian",
  // es: "Spanish",
  // el: "Greek",
  // fr: "French",
  // nl: "Dutch",
  // de: "German",
  // pt: "Portuguese",
};

const suppliersMapping = {
  "": "-----",
  innstant: "Innstant",
  hotelbeds: "Hotelbeds",
  webhotelier: "Webhotelier",
  miki: "Miki",
  hotelston: "Hotelston",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  EditHotelModal: modalGenericStyles.modal,
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
  },
  languages: {
    display: "grid",
    padding: variables.double_gap,
  },
  languagesButton: {
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "background .s, color .3s",
    "&:hover": {
      background: variables.colors.background.darkHover,
      color: variables.colors.text.light,
    },
  },
  sectionHeader: formSections.sectionHeader,
  formItem: {
    ...formInputGroup,
    padding: `${variables.normal_gap} 0rem `,
  },
  description: {
    gap: variables.normal_gap,
    "& label": {
      fontSize: "small",
    },
  },
  choosenButton: {
    extend: "languagesButton",
    backgroundColor: variables.colors.background.darkHover,
    color: variables.colors.text.light,
  },
  [`@media ${variables.media.smallscreen}`]: {
    modalCard: {
      minWidth: "65rem",
    },
    cardBody: {
      gridTemplateColumns: "repeat(2 , 1fr)",
    },
    cardCol2: {
      display: "grid",
      gridTemplateColumns: "repeat(2 , 1fr)",
    },
  },
});
function EditHotelModal({ onClose, pk }) {
  const [hotelForm, setHotelForm] = useState(jsonSchema.cast({}));
  const [activeLanguage, setActiveLanguage] = useState("en");

  const queryClient = useQueryClient();

  const classes = styles();

  const { data, isLoading } = useQuery({
    queryKey: ["RetrieveEditHotel", pk],
    queryFn: async () =>
      await retrieveHotel({
        pk: pk,
      }),
    onSuccess: (data) => {
      setHotelForm(
        jsonSchema.cast(_.get(data, "data", {}), { stripUnknown: true })
      );
      // setHotelForm(_.get(data, "data", {}));
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const mutation = useMutation({
    mutationFn: ({ payload }) => {
      return patchHotel({
        hotelPk: _.get(data, "data.pk"),
        payload,
      });
    },
    onSuccess: () => {
      toast.success("Hotel info updated");
      queryClient.invalidateQueries("Hotels");
      onClose();
    },
    onError: (error) => {
      genericRequestErrorHandler(error);
    },
  });

  const onSubmit = async () => {
    try {
      await jsonSchema.validate(hotelForm, { abortEarly: false });
      let payload = { ...hotelForm };
      if (_.get(hotelForm, "data.geodata.lng")) {
        payload["lng"] = Number.parseFloat(
          _.get(hotelForm, "data.geodata.lng")
        );
      }
      if (_.get(hotelForm, "data.geodata.lat")) {
        payload["lat"] = Number.parseFloat(
          _.get(hotelForm, "data.geodata.lat")
        );
      }
      mutation.mutate({ pk, payload });
    } catch (error) {
      handleValidationErrors(error);
    }
  };

  const loading = isLoading || mutation.isLoading;
  return (
    <div className={classes.EditHotelModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Edit Hotel Informations</span>
        </div>
        <TabStrip
          tabs={Object.entries(languages)}
          activeTab={activeLanguage}
          setActiveTab={setActiveLanguage}
        />
        {loading ? (
          <div className={classes.modalCardBody}>
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.modalCardBody}>
              <form>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Basic Information</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.formItem}>
                        <label>Name</label>
                        <input
                          type="text"
                          value={hotelForm.name}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              name: e.target.value,
                            }))
                          }
                          max={9999}
                        />
                      </div>
                      <div className={classes.formItem}>
                        <label>Address</label>
                        <input
                          type="text"
                          value={hotelForm.address}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              address: e.target.value,
                            }))
                          }
                        />
                      </div>

                      <div className={classes.formItem}>
                        <label>Supplier</label>
                        <select
                          name="supplier"
                          value={hotelForm.supplier}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              supplier: e.target.value,
                            }))
                          }>
                          {Object.entries(suppliersMapping).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.formItem}>
                        <label>Country</label>
                        <select
                          value={hotelForm.country_code}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              country_code: e.target.value,
                            }))
                          }>
                          {Object.entries(countries).map(
                            ([value, label], idx) => (
                              <option key={idx} value={value}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className={classes.formItem}>
                        <label>Rating</label>
                        <input
                          type="number"
                          value={hotelForm.rating}
                          // min={1}
                          // max={5}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              rating: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className={classes.formItem}>
                        <label>Custom Doc Score</label>
                        <input
                          type="number"
                          value={hotelForm.custom_doc_score}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              custom_doc_score: e.target.value,
                            }))
                          }
                          max={1999}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardCol1}>
                    <div className={classes.description}>
                      <label htmlFor="description">Description</label>
                      {Object.entries(languages).map(([key, label], idx) =>
                        key === activeLanguage ? (
                          <QuilEditor
                            key={idx}
                            value={_.get(
                              hotelForm,
                              `description_${activeLanguage}`
                            )}
                            onChange={(value) => {
                              let newForm = {
                                ...hotelForm,
                                [`description_${activeLanguage}`]: value,
                              };
                              setHotelForm(newForm);
                            }}
                          />
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.card}>
                  <div className={classes.cardHeader}>Geodata</div>
                  <div className={classes.cardBody}>
                    <div className={classes.cardCol2}>
                      <div className={classes.formItem}>
                        <label>Lat</label>
                        <input
                          type="number"
                          value={_.get(hotelForm, "geodata.lat")}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p,
                              geodata: { ...p.geodata, lat: e.target.value },
                            }))
                          }
                        />
                      </div>
                      <div className={classes.formItem}>
                        <label>Lng</label>
                        <input
                          type="number"
                          value={_.get(hotelForm, "geodata.lng")}
                          onChange={(e) =>
                            setHotelForm((p) => ({
                              ...p.geodata,
                              geodata: { ...p, lng: e.target.value },
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className={classes.modalCardActions}>
              <CustomButton appearance="ghost" onClick={onClose}>
                <strong>Cancel</strong>
              </CustomButton>
              <CustomButton
                appearance="primary"
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit({
                    pk: _.get(data, "data.pk"),
                    payload: hotelForm,
                  });
                }}>
                <strong>Submit</strong>
              </CustomButton>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
EditHotelModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  pk: PropTypes.number.isRequired,
};

export default EditHotelModal;
