import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchWebsites({ params }) {
  return authorizedAxios.get(`${VALAQUENTA_API_URL}/canaveral/websites/`, {
    params,
  });
}

export function retrieveWebsite({ uid }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/canaveral/websites/${uid}/`
  );
}

export function patchWebSite({ uid, payload }) {
  return loggingAxios.patch(
    `${VALAQUENTA_API_URL}/canaveral/websites/${uid}/`,
    payload
  );
}

export function createWebSite({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/canaveral/websites/`,
    payload
  );
}

export function deleteWebSites({ uid }) {
  return loggingAxios.delete(
    `${VALAQUENTA_API_URL}/canaveral/websites/${uid}/`
  );
}

export function translate({ payload }) {
  return loggingAxios.post(
    `${VALAQUENTA_API_URL}/translateengine/translate`,
    payload
  );
}
