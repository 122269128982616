import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import GenericBasicInfo from "../../../../../components/cards/GenericBasicInfo";
import {
  cardStyles,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import PropTypes from "prop-types";
import {
  checkEmailconfigVerification,
  retrieveEmailConfigReservation,
} from "../../../../../api/CRS/EmailConfiguration";
import _ from "lodash";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../../../components/Loader";
import { genericRequestErrorHandler } from "../../../../../api";
import { CustomButton } from "../../../../../components/CustomButton";
import EmailConfigLogsTab from "./EmailConfigLogsTab";
import TabStrip from "../../../../../components/TabStrip";

const trueFalseMapping = {
  true: "Yes",
  false: "No",
};

const emailTypeMapping = {
  "": "-----",
  VO: "Voucher",
  CXL: "Cancellation",
  RQ: "On Request",
  FA: "Failed Reservation",
  OFF: "Offer Notification",
};

const reservationTypeMapping = {
  ACC: "Accommodation",
  GEN: "General Service",
  MI: "Addon",
  TRF: "Transfers ",
  PKG: "Package ",
  FL: "Flights",
  COO: "Coordinator",
  TRA: "Train",
  FER: "Ferry",
  COA: "Coach",
  REST: "Restaurant",
  ACT: "Activity",
};

const statusMapping = {
  "": "-----",
  SU: "Success",
  FA: "Failed",
  PE: "Pending",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  DetailEmailConfigurationModal: {
    ...modalGenericStyles.modal,
  },
  modalCard: {
    ...modalGenericStyles.card,
    gridTemplateRows: "max-content max-content 1fr max-content",
  },
  modalCardHeader: {
    ...modalGenericStyles.cardHeader,
  },
  modalCardBody: {
    ...modalGenericStyles.cardBody,
    display: "grid",
  },
  modalCardActions: {
    ...modalGenericStyles.cardActions,
  },
  card: cardStyles.card,
  cardHeader: cardStyles.header,
  cardBody: {
    ...cardStyles.body,
    gap: variables.normal_gap,
    alignContent: "start",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: variables.normal_gap,
  },
  [`@media ${variables.media.smallscreen}`]: {
    cardBody: { justifyContent: "center" },
    row: {
      gridTemplateColumns: "2fr 1fr",
    },
  },
});

const DetailEmailConfigurationModal = ({ selectedId, onClose }) => {
  const classes = styles();

  const [activeTab, setActiveTab] = useState("basicInfo");

  const menuTabs = {
    basicInfo: "Basic Info",
    logs: "Logs",
  };

  const { data, isLoading: loading } = useQuery(
    ["emailsDetails", selectedId],
    async () => {
      return await retrieveEmailConfigReservation({
        id: selectedId,
      });
    },
    {
      onError: (error) => genericRequestErrorHandler(error),
    }
  );
  const VerificationEmailComponent = useQuery({
    queryKey: ["onVerificationEmail", selectedId],
    queryFn: () => {
      return checkEmailconfigVerification({
        id: selectedId,
      });
    },
    onError: (error) => genericRequestErrorHandler(error),
  });

  const basicDataCpl = [
    ["Title", "title"],
    ["Market type", "market_type"],
    ["Source entity", "source_entity"],
    [
      "Email Type",
      null,
      _.get(emailTypeMapping, _.get(data, "data.email_type")),
    ],
    ["Template Name", "template_name"],
    ["Sender", "sender"],
    ["Website Api Key", "website_api_key"],
    ["Version", "version"],
    [
      "Reservation Types",
      null,
      _.get(data, "data.reservation_types", []).join(", "),
    ],
    ["Is Global", null, _.get(trueFalseMapping, _.get(data, "data.is_global"))],

    ["Subject", "subject"],

    ["Status", null, _.get(statusMapping, _.get(data, "data.status"))],
  ];

  const creatorData = [
    ["Creator", "creator"],
    [
      "Created",
      null,
      DateTime.fromISO(_.get(data, "data.created")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  const editorData = [
    [
      "Edited",
      null,
      DateTime.fromISO(_.get(data, "data.edited")).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
    ],
  ];
  return (
    <div className={classes.DetailEmailConfigurationModal}>
      <div className={classes.modalCard}>
        <div className={classes.modalCardHeader}>
          <span>Email Configuration Details</span>
        </div>
        <TabStrip
          tabs={Object.entries(menuTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {loading !== true && data.status === "PE" && (
          <div className={classes.modalCardActions}>
            <CustomButton
              appearance="ghost"
              onClick={VerificationEmailComponent}>
              <strong>Check Email Verification</strong>
            </CustomButton>
          </div>
        )}
        <div className={classes.modalCardBody}>
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {activeTab === "basicInfo" && (
                <div className={classes.row}>
                  <div className={classes.cardBody}>
                    <GenericBasicInfo
                      header={"Basic Data"}
                      cardInfo={basicDataCpl}
                      objectData={_.get(data, "data", {})}
                    />
                  </div>
                  <div className={classes.cardBody}>
                    <GenericBasicInfo
                      header={"Creator Data"}
                      cardInfo={creatorData}
                      objectData={_.get(data, "data", {})}
                    />
                    <GenericBasicInfo
                      header={"Editor Data"}
                      cardInfo={editorData}
                      objectData={_.get(data, "data", {})}
                    />
                  </div>
                </div>
              )}
              {activeTab === "logs" && <EmailConfigLogsTab />}
            </React.Fragment>
          )}
        </div>
        <div className={classes.cardActions}>
          <CustomButton appearance="ghost" onClick={onClose}>
            <strong>Cancel</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
DetailEmailConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default DetailEmailConfigurationModal;
