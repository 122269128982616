import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { useState } from "react";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../../variables";
import { countries, onEnterDown } from "../../../../helpers";
import { CustomButton } from "../../../../components/CustomButton";

const suppliersOptions = [
  ["", "-----"],
  ["innstant", "Innstant"],
  ["hotelbeds", "Hotelbeds"],
  ["webhotelier", "Webhotelier"],
  ["miki", "Miki"],
  ["hotelston", "Hotelston"],
];

const ratingOptions = [
  ["", "-----"],
  ["0", "0 Stars"],
  ["1", "1 Star"],
  ["2", "2 Stars"],
  ["3", "3 Stars"],
  ["4", "4 Stars"],
  ["5", "5 Stars"],
];

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  filtersActions: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    justifyContent: "space-around",
    padding: [0, variables.normal_gap],
    width: "100%",
    boxSizing: "border-box",
  },
  btn: {
    minWidth: "8rem",
  },
  batchActions: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    padding: [0, variables.normal_gap],
    gridGap: variables.normal_gap,
    justifyContent: "center",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    actions: GenericNavbarsStyles.actions.smallscreen(),
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
    filtersActions: {
      paddingTop: variables.normal_gap,
      // gridTemplateColumns: "max-content",
      gridGap: variables.half_gap,
    },
    batchActions: {
      gridTemplateColumns: "max-content",
      gridGap: variables.half_gap,
    },
  },
  [`@media ${variables.media.retina}`]: {
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(6, 1fr)",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(8, 1fr)",
    },
  },
});
const Navbar = ({ filters, initialFilters, onApplyFilters, onGo }) => {
  const classes = navbarStyles();

  const [localFilters, setLocalFilters] = useState(filters);

  return (
    <div className={classes.Navbar}>
      <div className={classes.inputGroupOneLine}>
        <strong>Hotels</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroup}>
          <label>Uid</label>
          <input
            type="text"
            value={localFilters.uid}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, uid: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Hotel Name</label>
          <input
            type="text"
            value={localFilters.name}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                name: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Supplier</label>
          <select
            value={localFilters.supplier}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, supplier: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {suppliersOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Rating</label>
          <select
            value={localFilters.rating}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, rating: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {ratingOptions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Hotel Address</label>
          <input
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
            type="text"
            value={localFilters.address}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, address: e.target.value }))
            }
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Country</label>
          <select
            value={localFilters.country_code}
            onChange={(e) =>
              setLocalFilters((p) => ({ ...p, country_code: e.target.value }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(countries).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label>Created After</label>
          <input
            type="date"
            value={localFilters.created}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        {/* <div className={classes.inputGroup}>
          <label>Created Before</label>
          <input
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div> */}
      </div>
      <div className={classes.actions}>
        <div className={classes.filtersActions}>
          <CustomButton
            appearance="ghost"
            className={classes.btn}
            onClick={() =>
              setLocalFilters({
                ...initialFilters,
              })
            }>
            <strong>Reset</strong>
          </CustomButton>
          <CustomButton
            appearance="primary"
            className={classes.btn}
            onClick={() => onApplyFilters(localFilters)}>
            <strong>Apply Filters</strong>
          </CustomButton>
        </div>
        {/* <div className={classes.divider} />
        <div className={classes.batchActions}>
          <label>Batch Actions</label>
          <select
            value={action}
            onChange={(e) => setAction(e.target.value)}
            onKeyDown={(e) => onEnterDown(e, onGo, action)}
          >
            {actions.map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
          <button onClick={() => onGo(action)}>Go</button>
        </div> */}
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
};

export default Navbar;
