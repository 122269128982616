import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  GenericNavbarsStyles,
  inputGroup,
  variables,
} from "../../../variables";
import PropTypes from "prop-types";
import { onEnterDown } from "../../../helpers";
import { CustomButton } from "../../../components/CustomButton";

const paymentSupplierMapping = {
  "": "-----",
  STR: "Stripe",
  BCR: "Barclays Pay",
  VIV: "Viva",
};
const statusMapping = {
  "": "-----",
  AC: "Active",
  IN: "Inactive",
  CA: "Candidate",
};

const navbarStyles = createUseStyles({
  Navbar: {
    ...GenericNavbarsStyles.navbars.mobile(),
    gridTemplateRows: "max-content max-content max-content max-content ",
  },
  label: { ...inputGroup },
  divider: GenericNavbarsStyles.divider.mobile(),
  filters: GenericNavbarsStyles.filters.mobile(),
  actions: GenericNavbarsStyles.actions.mobile(),
  inputGroup: {
    ...inputGroup,
    width: "100%",
    "& input, select": {
      ...inputGroup["& input, select"],
      width: "100%",
    },
  },
  inputGroupOneLine: {
    ...inputGroup,
    gridColumn: "2 span",
    width: "100%",
  },
  [`@media ${variables.media.smallscreen}`]: {
    Navbar: {
      ...GenericNavbarsStyles.navbars.smallscreen(),
      gridTemplateColumns: "repeat(2, max-content) 1fr max-content",
      gridTemplateRows: "max-content",
    },
    divider: GenericNavbarsStyles.divider.smallscreen(),
    filters: {
      ...GenericNavbarsStyles.filters.smallscreen(),
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    actions: {
      ...GenericNavbarsStyles.actions.smallscreen(),
      padding: "0rem",
      paddingBottom: variables.half_gap,
    },
    inputGroup: {
      ...inputGroup,
    },
    inputGroupOneLine: { ...inputGroup.bigscreen, gridColumn: "1 span" },
  },
  [`@media ${variables.media.bigscreen}`]: {
    filters: {
      gridTemplateColumns: "repeat(5, 1fr)",
    },
  },
});

const Navbar = ({ filters, initialFilters, onApplyFilters, onAdd }) => {
  const classes = navbarStyles();
  const [localFilters, setLocalFilters] = useState(filters);

  return (
    <div className={classes.Navbar}>
      <div className={classes.inputGroupOneLine}>
        <strong>Websites</strong>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.filters}>
        <div className={classes.inputGroupOneLine}>
          <label>Name</label>
          <input
            type="text"
            value={localFilters.name__icontains}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                name__icontains: e.target.value,
              }))
            }
            onKeyDown={(e) =>
              onEnterDown(e, onApplyFilters, localFilters)
            }></input>
        </div>
        <div className={classes.inputGroup}>
          <label>Payment Supplier</label>
          <select
            value={localFilters.payment_supplier}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                payment_supplier: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(paymentSupplierMapping).map(
              ([value, label], idx) => (
                <option key={idx} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
        </div>

        <div className={classes.inputGroup}>
          <label>Created After</label>
          <input
            type="date"
            value={localFilters.created__gte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__gte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Created Before</label>
          <input
            type="date"
            value={localFilters.created__lte}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                created__lte: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Status</label>
          <select
            value={localFilters.status}
            onChange={(e) =>
              setLocalFilters((p) => ({
                ...p,
                status: e.target.value,
              }))
            }
            onKeyDown={(e) => onEnterDown(e, onApplyFilters, localFilters)}>
            {Object.entries(statusMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classes.actions}>
        <CustomButton
          appearance="ghost"
          onClick={() =>
            setLocalFilters({
              ...initialFilters,
            })
          }>
          <strong>Reset</strong>
        </CustomButton>
        <CustomButton
          appearance="primary"
          onClick={() => onApplyFilters(localFilters)}>
          <strong>Apply Filters</strong>
        </CustomButton>
        <div className={classes.divider}></div>
        <CustomButton appearance="ghost" onClick={onAdd}>
          <strong>Add</strong>
        </CustomButton>
      </div>
    </div>
  );
};
Navbar.propTypes = {
  filters: PropTypes.object.isRequired,
  initialFilters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
export default Navbar;
