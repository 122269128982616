import _ from "lodash";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  tableStyles,
  variables,
} from "../../../../variables";
import Navbar from "./Navbar";
import {
  fetchAccReservations,
  getAccommodationReservationStats,
} from "../../../../api/CRS/Accommodations";
import ControlStrip from "../../../../components/ControlStrip";
import Loader from "../../../../components/Loader";
import {
  handleApplyFilters,
  medWeekdayDate,
  medWeekdayDatetimer,
} from "../../../../helpers";
import DetailAccommodationsModal from "../AccommodationsModals/DetailAccommodationsModal";
import ReservationStats from "../../CommonCRSModals/ReservationStats/ReservationStats";
import { commonTableHeaders } from "../../helpers";
import { useQueries } from "@tanstack/react-query";
import HamburgerMenu from "../../../../components/HamburgerMenu";
import { genericRequestErrorHandler } from "../../../../api";
import * as yup from "yup";
import { useListPageSidebar } from "../../../../hooks";
import { DateTime } from "luxon";
import { CustomButton } from "../../../../components/CustomButton";
import CRSCxlCell from "../../../../components/CRSCxlCell";

const initialFilters = {
  limit: 20,
  page: 1,
  ordering: "",
  discending: "",
  market_type: "",
  check_in__gte: "",
  check_in__lte: "",
  check_out__gte: "",
  check_out__lte: "",
  destination_name: "",
  name__icontains: "",
  external_creator: "",
  status: "",
  reference: "",
  created__gte: "",
  created__lte: "",
  external_reference__icontains: "",
  source_entity: "",
  network_search: "",
  provider__icontains: "",
  supplier_reservation_reference: "",
};

const navbarSchema = yup.object().shape({
  created__gte: yup.date(),
  created__lte: yup.date().max(new Date(), "Date cannot be in the future"),
});

const accommodationStyles = createUseStyles({
  Accomodation: {
    ...genericIndexesStyles.indexes.mobile(),
    gridTemplateRows: "auto auto auto  1fr",
  },
  tableContainer: genericIndexesStyles.tableContainer.mobile,
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  bodyRow: tableStyles.body.row,
  tablecell: tableStyles.body.cell,
  actionCell: {
    ...tableStyles.body.cell,
    gap: variables.half_gap,
  },
  cxltablecell: { ...tableStyles.body.cell, minWidth: "9rem" },
  optionBookingContainer: {
    display: "inline-block",
    width: "fit-content",
    padding: "4px 10px 4px 10px",
    borderRadius: "5px",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  optionBookingOrange: {
    backgroundColor: "orange",
  },
  optionBookingRed: { backgroundColor: "red" },
  optionBookingGreen: { backgroundColor: "green" },
  optionBookingBlue: { backgroundColor: "blue" },
  CheckinOutCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },
  datesCell: { ...tableStyles.body.cell, minWidth: "9rem", width: "9rem" },

  paxCell: { ...tableStyles.body.cell, minWidth: "7rem" },
  [`@media ${variables.media.smallscreen}`]: {
    tableCell: {
      padding: `calc(${variables.normal_gap} * 2.5) 0`,
    },
  },
});

const Accommodations = () => {
  const classes = accommodationStyles();

  useListPageSidebar();

  const [filters, setFilters] = useState(initialFilters);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [reference, setReference] = useState(null);

  const handleApplyLocalFilters = (filters) => {
    handleApplyFilters(filters, setFilters, navbarSchema);
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["accommodations", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          return fetchAccReservations({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
        onError: (error) => genericRequestErrorHandler(error),
      },
      {
        queryKey: ["accommodationStats", filters],
        queryFn: async () => {
          let correctPayload = { ...filters };
          if (filters.created__lte) {
            correctPayload["created__lte"] = DateTime.fromISO(
              filters.created__lte
            ).toISO();
          }
          if (filters.created__gte) {
            correctPayload["created__gte"] = DateTime.fromISO(
              filters.created__gte
            ).toISO();
          }
          return getAccommodationReservationStats({
            params: correctPayload,
          });
        },
        enabled: !showDetailModal,
      },
    ],
  });
  const tableHeaders = commonTableHeaders({
    "Supplier Reservation Reference": (val) =>
      val.supplier_reservation_reference,
    Destination: (val) => val.destination_name,
    Name: (val) => _.get(val, "accommodation_meta.name"),
    // Address: (val) => _.get(val, "accommodation_meta.address"),
    Pax: (val) => {
      const pax = _.get(val, "pax", {});
      const children = _.get(pax, "children", 0) ? _.get(pax, "children") : "0";
      const adults = _.get(pax, "adults", 0) || "N/A";
      return `Adults: ${adults}, Children: ${children}`;
    },
    Tags: (val) => _.get(val, "tags", []).join(","),
    Rating: (val) => _.get(val, "accommodation_meta.rating"),
    "Check In": (val) => val.check_in,
    "Check Out": (val) => val.check_out,
    Cxl: (val) => <CRSCxlCell val={val} />,
    Providers: (val) => _.get(val, "providers", []).join("."),
    "Booking Mode": (val) => val.booking_mode_display,
    "Market Type": (val) => val.market_type,
  });

  return (
    <div id="AccommodationsListPage" className={classes.Accomodation}>
      <Navbar
        filters={filters}
        onApplyFilters={handleApplyLocalFilters}
        initialFilters={initialFilters}
      />
      <HamburgerMenu />
      {!_.get(results, "1.isLoading", true) &&
      _.get(results, "1.data", null) ? (
        <ReservationStats
          title="Reservation Stats"
          stats={_.get(results, "1.data.data.stats_per_currency")}
        />
      ) : (
        <Loader />
      )}
      <ControlStrip
        limit={_.get(filters, "limit")}
        page={filters.page}
        count={_.get(results, "0.data.data.count")}
        onChangeLimit={(limit) => setFilters({ ...filters, limit, page: 1 })}
        onChangePage={(page) => setFilters({ ...filters, page })}
        orderOptions={[["created", "Created"]]}
        onChangeOrderOptions={(ordering) =>
          setFilters({ ...filters, ordering })
        }
      />
      {_.get(results, "0.data.data.isLoading", false) ? (
        <Loader />
      ) : (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                <th className={classes.headCell}>#</th>
                {Object.keys(tableHeaders).map((value, idx) => (
                  <th className={classes.headCell} key={idx}>
                    {value}
                  </th>
                ))}
                <th className={classes.headCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {_.get(results, "0.data.data.results", []).map((val, idx) => (
                <tr className={classes.bodyRow} key={idx}>
                  <td className={classes.tablecell}>{idx + 1}</td>
                  {Object.entries(tableHeaders).map((entry, ridx) => {
                    var cell = null;
                    const fn = entry[1];
                    switch (entry[0].toLowerCase()) {
                      case "pax":
                        cell = fn(val) || "N/A";
                        return (
                          <td className={classes.paxCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "check in":
                        cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                        return (
                          <td className={classes.CheckinOutCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "check out":
                        cell = fn(val) ? medWeekdayDate(fn(val)) : "N/A";
                        return (
                          <td className={classes.CheckinOutCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      case "created":
                        cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                        return (
                          <td className={classes.datesCell} key={ridx}>
                            {cell}
                          </td>
                        );
                      default:
                        cell = fn(val) || "N/A";
                        break;
                    }
                    return (
                      <td className={classes.tablecell} key={ridx}>
                        {cell}
                      </td>
                    );
                  })}
                  <td className={classes.actionCell}>
                    <CustomButton
                      id={`detailACCButton_${idx}`}
                      appearance="ghost"
                      onClick={() => {
                        setReference(val.reference);
                        setShowDetailModal(true);
                      }}>
                      <strong>Details</strong>
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showDetailModal && (
        <DetailAccommodationsModal
          onClose={() => setShowDetailModal(false)}
          reference={reference}
        />
      )}
    </div>
  );
};
export default Accommodations;
