import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  formInputGroup,
  formSections,
  inputGroup,
  modalGenericStyles,
  variables,
} from "../../../../../variables";
import { toast } from "react-toastify";
import Loader from "../../../../../components/Loader";
import _ from "lodash";
import { createDocuments } from "../../../../../api/CRM/Documents";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomButton } from "../../../../../components/CustomButton";

const typeMapping = {
  "": "-----",
  ID: "Id",
  VOUCHER: "Voucher",
  PASSPORT: "Passport",
};

const styles = createUseStyles({
  ...modalGenericStyles,
  ...cardStyles,
  AddUsersDocumentsModal: {
    ...cardStyles.cardBody,
    display: "grid",
    minHeight: "5vh",
    gridGap: variables.normal_gap,
  },
  cardBody: {
    display: "grid",
    gridTemplateRows: "1fr 1fr max-content",
    // width: "100%",
    padding: variables.normal_gap,
    gap: variables.normal_gap,
    overflow: "auto",
  },
  inputGroup: formInputGroup,

  inputGroupOneLineBtn: {
    display: "grid",
    gridColumn: "span 2",
    gap: variables.half_gap,
    paddingBottom: variables.half_gap,
    justifyContent: "end",
    gridTemplateColumns: "repeat(2, auto)",
    width: "100%",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: variables.half_gap,
    padding: variables.half_gap,
    maxWidth: "80vw",
    alignItems: "end",
  },
  formSectionHeader: formSections.sectionHeader,
  body: { display: "grid", gridTemplateColumns: "repeat (2, auto)" },
  inputGroupOneLine: {
    ...inputGroup,
    width: "100%",
    paddingTop: variables.normal_gap,
    justifyContent: "center",
  },
  cardActions: {
    ...modalGenericStyles.cardActions,
    width: "100%",
    textAlign: "end",
  },
  actionCell: genericIndexesStyles.actionCell.mobile,

  [`@media ${variables.media.smallscreen}`]: {
    cardBody: {
      display: "grid",
      gridTemplateColumns: "repeat(2, max-content)",
      justifyContent: "center",
    },
    cardActions: {
      ...cardStyles.actions,
      gridColumn: "2 span",
      height: "1rem",
    },
  },
});

const AddUsersDocumentsModal = ({
  sourceEntity,
  queryKey,
  tags,
  onCloseDocumentModal,
}) => {
  const classes = styles();
  const queryClient = useQueryClient();

  const [document, setDocument] = useState(null);
  const [type, setType] = useState("");

  const mutation = useMutation({
    mutationFn: () => {
      const formData = new FormData();
      formData.append("source_entity", sourceEntity);
      formData.append("file", document);
      tags.forEach((tg) => {
        formData.append("tags", tg);
      });
      formData.append("tags", `type_${type}`);
      return createDocuments({ payload: formData });
    },
    onSuccess: () => {
      toast.success("New Document(s) Added");
      queryClient.invalidateQueries(`${queryKey}`);
      onCloseDocumentModal();
    },
    onError: (error) => toast.error(error.message),
  });

  const onCloseAdd = () => {
    onCloseDocumentModal();
  };

  return (
    <div className={classes.AddUsersDocumentsModal}>
      {mutation.isLoading && <Loader onTop={true} />}
      <div className={classes.cardHeader}>
        {_.startCase("add")} Document Info
      </div>
      <div className={classes.cardBody}>
        <div className={classes.inputGroupOneLine}>
          <label htmlFor="type">Type</label>
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}>
            {Object.entries(typeMapping).map(([value, label], idx) => (
              <option key={idx} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroupOneLine}>
          <label htmlFor="document">Document</label>
          <input
            type="file"
            name="document"
            accept=".pdf,.docx,.doc,.txt,.xls,.xlsx,.csv,.odp,.ods,.odt,.ppt,.pptx,.rtf,"
            onChange={(e) => setDocument(e.target.files[0])}
          />
        </div>
        <div className={classes.cardActions}>
          <CustomButton
            appearance="ghost"
            onClick={(e) => {
              e.preventDefault();
              onCloseAdd();
            }}>
            <strong>Cancel</strong>
          </CustomButton>
          <CustomButton
            appearance="primary"
            onClick={(e) => {
              e.preventDefault();
              mutation.mutate();
            }}>
            <strong>Submit</strong>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
AddUsersDocumentsModal.propTypes = {
  sourceEntity: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  onCloseDocumentModal: PropTypes.func.isRequired,
};
export default AddUsersDocumentsModal;
