import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import {
  genericIndexesStyles,
  cardStyles,
  tableStyles,
} from "../../../../../variables";
import PropTypes from "prop-types";
import Loader from "../../../../../components/Loader";
import { fetchDocuments } from "../../../../../api/CRM/Documents";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import DeleteDocumentCRSModal from "../DocumentsCRSModals/DeleteDocumentCRSModal";
import AddDocumentsCRSModal from "../DocumentsCRSModals/AddDocumentsCRSModal";
import { toast } from "react-toastify";
import { CustomButton } from "../../../../../components/CustomButton";

const initialFilters = {
  tags: "",
  sourceEntity: "",
};

const documentsCardStyles = createUseStyles({
  ...cardStyles,
  DocumentsCRSCard: cardStyles.card,
  headCell: tableStyles.head.cell,
  headRow: tableStyles.head.row,
  tableCell: tableStyles.body.cell,
  actionCell: genericIndexesStyles.actionCell.mobile,
});

export const DocumentsCRSCard = ({
  title,
  reference,
  service_type,
  id,
  sourceEntity,
  queryKey,
  tags,
}) => {
  const classes = documentsCardStyles();
  const [filters] = useState(initialFilters);
  const [deleteUid, setDeleteUid] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: [queryKey, filters],
    queryFn: () =>
      fetchDocuments({
        params: { source_entity: sourceEntity, tags: tags.join(",") },
      }),
    onError: (error) => toast.error(error),
    enabled: !showAddModal && !showDeleteModal,
  });
  return (
    <div id="DocumentsCRSCard" className={classes.DocumentsCRSCard}>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {!showAddModal && !showDeleteModal && (
            <React.Fragment>
              <div className={classes.header}>
                <span>{title}</span>
                <CustomButton
                  id="addCRSDocumentButton"
                  appearance="ghost"
                  onClick={() => {
                    setShowAddModal(true);
                  }}>
                  <strong>Add</strong>
                </CustomButton>
              </div>
              <div className={classes.body}>
                <table>
                  <thead>
                    <tr className={classes.headRow}>
                      <th className={classes.headCell}>#</th>
                      <th className={classes.headCell}>Creator Username</th>
                      <th className={classes.headCell}>Creator First Name</th>
                      <th className={classes.headCell}>Creator Last Name</th>
                      <th className={classes.headCell}>Creator Email</th>
                      <th className={classes.headCell}>Source Entity</th>
                      <th className={classes.headCell}>File Name</th>
                      <th className={classes.headCell}>Tags</th>
                      <th className={classes.headCell}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.get(data, "data", []).map((doc, idx) => (
                      <tr key={idx}>
                        <td className={classes.tableCell}>{idx + 1}</td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "creator_metadata.username") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "creator_metadata.first_name") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "creator_metadata.last_name") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "creator_metadata.email") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "creator_metadata.source_entity") ||
                            "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "filename") || "N/A"}
                        </td>
                        <td className={classes.tableCell}>
                          {_.get(doc, "tags_display", []).join(",") || "N/A"}
                        </td>
                        <td className={classes.actionCell}>
                          <CustomButton
                            id={`DeleteCRSDocumentButton_${idx}`}
                            appearance="ghost"
                            onClick={() => {
                              setDeleteUid(doc.uid);
                              setShowDeleteModal(true);
                            }}>
                            <strong>Delete</strong>
                          </CustomButton>
                          <CustomButton
                            appearance="ghost"
                            onClick={() => {
                              window.open(doc.path, "_blank", "noreferrer");
                            }}>
                            <strong>Download</strong>
                          </CustomButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
          {showAddModal && (
            <AddDocumentsCRSModal
              sourceEntity={sourceEntity}
              queryKey={queryKey}
              entityId={id}
              tags={tags}
              onClose={() => {
                setShowAddModal(false);
              }}
            />
          )}
          {showDeleteModal && (
            <DeleteDocumentCRSModal
              uid={deleteUid}
              onClose={() => {
                setShowDeleteModal(false);
              }}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

DocumentsCRSCard.propTypes = {
  queryKey: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  sourceEntity: PropTypes.string.isRequired,
};
