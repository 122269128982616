import React from "react";
import { createUseStyles } from "react-jss";
import {
  cardStyles,
  modalGenericStyles,
  tableStyles,
  variables,
} from "../../../variables";
import PropTypes from "prop-types";
import _ from "lodash";
import { medWeekdayDatetimer } from "../../../helpers";

const reservationEventsModalStyles = createUseStyles({
  ...modalGenericStyles,
  ReservationEventsModal: {
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    overflow: "hidden",
  },
  tableContainer: {
    overflow: "auto",
  },
  table: tableStyles.table,
  headRow: tableStyles.head.row,
  headCell: tableStyles.head.cell,
  tablecell: tableStyles.body.cell,
  cardActions: { ...cardStyles.actions, padding: variables.half_gap },
  exData: {
    ...tableStyles.body.cell,
    overflow: "auto",
    display: "grid",
    maxWidth: "35rem",
  },
});
const ReservationEventsModal = ({ header, events }) => {
  const classes = reservationEventsModalStyles();
  const tableHeaders = {
    Message: (val) => val.msg,
    "Extra Data": (val) => {
      if (_.isEmpty(val.extra_data)) {
        return null;
      }
      return (
        <div id="ReservationEvents" className={classes.exData}>
          <table className={classes.basicDatatable}>
            <thead className={classes.thead}>
              <tr className={classes.headRow}>
                {typeof val.extra_data === "object"
                  ? Object.keys(val.extra_data).map((eventValue) => (
                      <th className={classes.headCell} key={eventValue}>
                        {typeof eventValue === "string"
                          ? eventValue
                          : JSON.stringify(eventValue)}
                      </th>
                    ))
                  : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(val.extra_data).map((key) => {
                  const event = _.get(val.extra_data, key) || "N/A";
                  return (
                    <td key={` key ${key}`}>
                      {typeof event === "string"
                        ? event
                        : JSON.stringify(event)}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      );
    },
    Created: (val) => val.timestamp,
  };
  return (
    <div className={classes.ReservationEventsModal}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <span>{header}</span>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead className={classes.thead}>
                <tr className={classes.headRow}>
                  <th className={classes.headCell}>#</th>
                  {Object.keys(tableHeaders).map((value, idx) => (
                    <th className={classes.headCell} key={idx}>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {events.map((val, idx) => (
                  <tr key={idx}>
                    <td className={classes.tablecell}>{idx + 1}</td>
                    {Object.entries(tableHeaders).map((entry, ridx) => {
                      var cell = null;
                      const fn = entry[1];
                      switch (entry[0].toLowerCase()) {
                        case "created":
                          cell = fn(val) ? medWeekdayDatetimer(fn(val)) : "N/A";
                          break;
                        case "extra_data":
                          cell = fn(val) || "N/A";
                          return (
                            <td className={classes.exData} key={ridx}>
                              {cell}
                            </td>
                          );
                        default:
                          cell = fn(val) || "N/A";
                          break;
                      }
                      return (
                        <td className={classes.tablecell} key={ridx}>
                          {cell}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className={classes.cardActions}>
          <Button text="Cancel" onClick={() => onClose()} />
        </div> */}
      </div>
    </div>
  );
};
ReservationEventsModal.propTypes = {
  header: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
};
export default ReservationEventsModal;
