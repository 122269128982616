import { VALAQUENTA_API_URL } from "../..";
import { authorizedAxios, loggingAxios } from "../../../App";

export function fetchTransferReservations({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/transfer/`,
    {
      params,
    }
  );
}

export function retrieveTransferReservation({ reference }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/transfer/${reference}/`
  );
}
export async function patchTransferReservation({ reference, payload }) {
  return await loggingAxios.patch(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/transfer/${reference}/`,
    payload
  );
}
export function getTransferReservationStats({ params }) {
  return authorizedAxios.get(
    `${VALAQUENTA_API_URL}/crs/internal/reservations/transfer/stats`,
    { params }
  );
}
